body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: Seneca, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-size: 1.3em;
}

input {
  font-family: Seneca, sans-serif;
}

button {
  font-family: Seneca, sans-serif;
}

textarea {
  font-family: Seneca, sans-serif;
}

@font-face {
  font-family: Seneca;
  src: url(./assets/fonts/Muli/Muli-Regular.ttf);
}

@font-face {
  font-family: Seneca;
  src: url(./assets/fonts/Muli/Muli-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Seneca;
  src: url(./assets/fonts/Muli/Muli-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: Seneca;
  src: url(./assets/fonts/Muli/Muli-BoldItalic.ttf);
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: Seneca;
  src: url(./assets/fonts/Muli/Muli-ExtraLight.ttf);
  font-weight: 300;
}